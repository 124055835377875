import Link from 'next/link';
import Image from 'next/image';
import fblogo from '@/images/footer-fb.svg';
import instalogo from '@/images/footer-insta.svg';
import GorinLogo from '@/images/homepage/gorin-small-logo.svg';
import { useState, useEffect } from 'react';
import { checkImgUrl } from '@/datalayer/config';
import { useSiteInfo } from '@/context/SiteInfoContext';
import ApiService from 'services/ApiService';
// Utils
const isAbsoluteUrl = (url) =>
  url.indexOf('http://') === 0 || url.indexOf('https://') === 0;
const getLogo = (platform) => {
  if (platform === 'instagram') return instalogo;
  if (platform === 'facebook') return fblogo;
};

export default function Footer() {
  let [footerInfo, setFooterInfo] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const footerInfoResponse =
        await ApiService.instance.get('/api/getFooterInfo');
      setFooterInfo(footerInfoResponse.data);
    };
    fetchData();
  }, []);

  return (
    footerInfo && (
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="footer_left">
                <Logo footerInfo={footerInfo} />
              </div>

              <div className="footer_center">
                <ul>
                  <Links footerInfo={footerInfo} />
                  <MobileSocialLinks footerInfo={footerInfo} />
                </ul>
              </div>

              <div className="footer_right">
                <DesktopSocialLinks footerInfo={footerInfo} />
              </div>
              <Copyright />
            </div>
          </div>
        </div>
      </footer>
    )
  );
}

function Logo({ footerInfo }) {
  return (
    footerInfo.footer &&
    footerInfo.footer.length !== 0 &&
    footerInfo.footer[0].logo &&
    footerInfo.footer[0].logo.id && (
      <Image
        height={48}
        width={120}
        alt="logo"
        src={checkImgUrl(footerInfo.footer[0].logo.id)}
      />
    )
  );
}

function Links({ footerInfo }) {
  return (
    footerInfo &&
    footerInfo.footer_links &&
    footerInfo.footer_links.length !== 0 && (
      <>
        {footerInfo.footer_links.map((link) => {
          return (
            <li key={link.href}>
              {isAbsoluteUrl(link.href) ? (
                <a href={link.href} rel="noreferrer noopener">
                  {link.label}
                </a>
              ) : (
                <Link href={link.href}>
                  <a>{link.label}</a>
                </Link>
              )}
            </li>
          );
        })}
      </>
    )
  );
}

function DesktopSocialLinks({ footerInfo }) {
  return (
    footerInfo &&
    footerInfo.social_media_links &&
    footerInfo.social_media_links.length !== 0 && (
      <ul>
        {footerInfo.social_media_links.map((smLink) => {
          return (
            <li key={smLink.url}>
              <Link href={smLink.url} target="_blank">
                <a>
                  <Image alt={smLink.platform} src={getLogo(smLink.platform)} />
                </a>
              </Link>
            </li>
          );
        })}
      </ul>
    )
  );
}

function MobileSocialLinks({ footerInfo }) {
  return (
    footerInfo &&
    footerInfo.social_media_links &&
    footerInfo.social_media_links.length !== 0 && (
      <li className="social-mobile">
        {footerInfo.social_media_links.map((smLink) => {
          return (
            <Link href={smLink.url} target="_blank" key={smLink.url}>
              <a>
                <Image alt={smLink.platform} src={getLogo(smLink.platform)} />
              </a>
            </Link>
          );
        })}
      </li>
    )
  );
}

function Copyright() {
  const { siteInfo } = useSiteInfo();
  return (
    <div className="footer_copyright">
      <p>
        <span>{siteInfo.site_name} © 2024</span>{' '}
        <Link href="/privacy">
          <a className="grey-link">Privacy</a>
        </Link>{' '}
        &{' '}
        <Link href="/terms">
          <a className="grey-link">Terms</a>
        </Link>
      </p>
      <p className="gorin-logo">
        <span>Developed by</span>{' '}
        <a rel="noopener noreferrer" href="https://gorinsystems.com/">
          <Image src={GorinLogo} alt="Gorin Systems Logo" />
        </a>
      </p>
    </div>
  );
}
