import { SubscriptionContext } from 'context/SubscriptionContext';
import { useContext } from 'react';

export const useActiveSubscription = () => {
  const {
    activeSubscription,
    subscriptionEndDate,
    subscriptionStatus,
    handleCheck,
  } = useContext(SubscriptionContext);
  return {
    activeSubscription,
    subscriptionEndDate,
    subscriptionStatus,
    handleCheck,
  };
};
