import { signOut } from 'next-auth/react';
import { Button } from 'react-bootstrap';
import Link from 'next/link';

import MdcHomeOutline from '@meronex/icons/mdc/MdcHomeOutline';
import MdcContentSaveOutline from '@meronex/icons/mdc/MdcContentSaveOutline';
import FaRegListAlt from '@meronex/icons/fa/FaRegListAlt';
import RiAccountCircleLine from '@meronex/icons/ri/RiAccountCircleLine';
import BsGear from '@meronex/icons/bs/BsGear';
import FiLogOut from '@meronex/icons/fi/FiLogOut';
import { useRouter } from 'next/router';

function MobileMenu({ hideMobileMenu }) {
  const router = useRouter();

  return (
    <>
      <ul className="sidebarmenu mobile">
        <li
          onClick={hideMobileMenu}
          className={router.pathname == '/' ? 'active' : ''}
        >
          <Link href="/">
            <a>
              <MdcHomeOutline />
              Home
            </a>
          </Link>
        </li>
        <li
          onClick={hideMobileMenu}
          className={router.pathname == '/my-designs' ? 'active' : ''}
        >
          <Link href="/my-designs">
            <a>
              <MdcContentSaveOutline />
              My Designs
            </a>
          </Link>
        </li>
        <li
          onClick={hideMobileMenu}
          className={router.pathname == '/orders' ? 'active' : ''}
        >
          <Link href="/orders">
            <a>
              <FaRegListAlt />
              Orders
            </a>
          </Link>
        </li>
        <li
          onClick={hideMobileMenu}
          className={router.pathname == '/profile' ? 'active' : ''}
        >
          <Link href="/profile">
            <a>
              <BsGear /> Settings
            </a>
          </Link>
        </li>
        <li>
          <Button variant="primary" onClick={() => signOut()}>
            <FiLogOut /> Logout
          </Button>
        </li>
      </ul>
    </>
  );
}

export default MobileMenu;
