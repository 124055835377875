import { Nav, Navbar, NavDropdown, Button } from 'react-bootstrap';
import Link from 'next/link';

function SubNavItem({ items, hideMobileMenu, buildLink, filters }) {
  return (
    <NavDropdown title="" id="basic-nav-dropdown" renderMenuOnMount={true}>
      {items.map((_child) => {
        return (
          <Link
            href={`/${_child.parent.slug}/${_child.slug}${buildLink(filters)}`}
            passHref
            key={_child.cat_id}
          >
            <NavDropdown.Item onClick={hideMobileMenu}>
              {_child.category_name}
            </NavDropdown.Item>
          </Link>
        );
      })}
    </NavDropdown>
  );
}

export default SubNavItem;
