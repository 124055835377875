import { useSession } from 'next-auth/react';
import { useSiteInfo } from '@/context/SiteInfoContext';

import LoginButtons from './LoginButtons';
import Avatar from './Avatar';
import MenuDropdown from './MenuDropdown';
import UpgradeButton from './UpgradeButton';
import AccountSuspensionModal from '@/components/modals/AccountSuspensionModal';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export default function Authpopup({ hideMobileMenu }) {
  const { data: session, status } = useSession();
  const { siteInfo } = useSiteInfo();
  const subscription = siteInfo ? siteInfo.pro_subscription : null;
  const [userDisabled, setUserDisabled] = useState(false);

  const router = useRouter();
  const params = { ...router.query };

  const removeQueryParam = (param) => {
    delete params[param];

    router.replace(
      {
        pathname: router.pathname,
        query: params,
      },
      undefined,
      {
        scroll: false,
        shallow: true,
      },
    );
  };

  useEffect(() => {
    if (params.accountDisabled && params.accountDisabled === 'true')
      setUserDisabled(true);
    else setUserDisabled(false);
  }, [params]);

  return (
    <div className="user_option">
      {status === 'authenticated' ? (
        <>
          <div className="usermenuouter desktop">
            {subscription && <UpgradeButton />}
            <div className="usermenuinner">
              <Avatar session={session} />
              <MenuDropdown />
            </div>
          </div>

          <div className="usermenuouter mobile">
            <button type="button" className="usermenu btn btn-primary">
              <span>
                {session.user.first_name != null
                  ? session.user.first_name.slice(0, 1)
                  : ''}
                {session.user.last_name != null
                  ? session.user.last_name.slice(0, 1)
                  : ''}
              </span>
              {session.user.first_name != null ? session.user.first_name : ''}{' '}
              {session.user.last_name != null ? session.user.last_name : ''}
            </button>
          </div>
        </>
      ) : (
        <LoginButtons hideMobileMenu={hideMobileMenu} status={status} />
      )}

      <AccountSuspensionModal
        show={userDisabled}
        closeModal={() => {
          removeQueryParam('accountDisabled');
        }}
      />
    </div>
  );
}
