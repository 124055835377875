import { Nav } from 'react-bootstrap';
import Link from 'next/link';
import SubNavItem from './SubNavItem';
import { useFilters } from '@/context/FilterContext';

function NavItem({ category, hideMobileMenu }) {
  const { filters } = useFilters();

  const buildLink = (filters) => {
    const params = new URLSearchParams();
    if (filters.type.length > 0)
      params.append('product_type', JSON.stringify(filters.type));
    if (filters.language.length > 0)
      params.append('product_language', JSON.stringify(filters.language));
    if (filters.free) params.append('free', 'true');

    const paramCount = Array.from(params.keys()).length;

    return paramCount !== 0 ? '?' + params.toString() : '';
  };

  return (
    <>
      <Nav.Item className={category.child.length ? 'has-children' : ''}>
        <Link href={`/${category.slug}${buildLink(filters)}`} passHref>
          <Nav.Link onClick={hideMobileMenu}>{category.category_name}</Nav.Link>
        </Link>
        {/* If the Item has children show Sub Navigation */}
        {category.child.length > 0 && (
          <SubNavItem
            filters={filters}
            buildLink={buildLink}
            items={category.child}
            hideMobileMenu={hideMobileMenu}
          />
        )}
      </Nav.Item>
    </>
  );
}

export default NavItem;
