import Link from 'next/link';
import useRole from '@/components/hooks/useRole';
import { signOut, useSession } from 'next-auth/react';
import { toast } from 'react-toastify';
import Products from 'services/ProductsService';
import { useSiteInfo } from '@/context/SiteInfoContext';

function MenuDropdown() {
  const { role } = useRole();
  const { data: session, status } = useSession();
  const { siteInfo } = useSiteInfo();

  const createProject = async () => {
    try {
      const savedID = await Products.createNewProduct(
        session.user.id,
        siteInfo.id,
      );
      window.open(`/project/${savedID.create_saveddesigns_item.id}`, '_blank');
    } catch (e) {
      console.error(e);
      toast.error('Can Not Create New design. Please Try Again.');
    }
  };

  return (
    <>
      <ul className="usermenuhover">
        {(role === 'uploader' || role === 'designer') && (
          <li>
            <button onClick={() => createProject()}>Create New Design</button>
          </li>
        )}
        <li>
          <Link href="/my-designs">
            <a>My Designs</a>
          </Link>
        </li>
        <li>
          <Link href="/orders">
            <a>Orders</a>
          </Link>
        </li>
        <li>
          <Link href="/autofill">
            <a>Autofill</a>
          </Link>
        </li>
        <li>
          <Link href="/profile">
            <a>Settings</a>
          </Link>
        </li>
        <li>
          <button onClick={() => signOut()}>Logout</button>
        </li>
      </ul>
    </>
  );
}

export default MenuDropdown;
