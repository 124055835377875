import { useEffect, useState } from 'react';
import CloseChatIcon from 'assets/jsx-icons/CloseChatIcon';

export default function AnnouncementBar({ announcementText }) {
  const [isVisible, setIsVisible] = useState(null);

  const closeAnnouncementBar = () => {
    sessionStorage.setItem('announcementViewed', true);
    setIsVisible(false);
  };

  useEffect(() => {
    const announcementViewed = sessionStorage.getItem('announcementViewed');
    if (!announcementViewed || announcementViewed != 'true') {
      setIsVisible(true);
    }
  }, []);

  return isVisible ? (
    <div className="announcement-bar">
      <div>
        <button className="ann-btn" onClick={closeAnnouncementBar}>
          <CloseChatIcon />
        </button>
        <div dangerouslySetInnerHTML={{ __html: announcementText }}></div>
      </div>
    </div>
  ) : null;
}
