import Head from 'next/head';
import { hexToRgb } from 'utils/hexToRGBString';

const StylesContainer = ({ siteInfo }) => {
  return (
    <>
      <Head>
        <style>
          {siteInfo.css_variables}
          {`
          :root{
            ${siteInfo.primary_color ? `--kg-primary-rgb: ${hexToRgb(siteInfo.primary_color)} !important;` : ''}
            ${siteInfo.secondary_color ? `--kg-secondary-rgb: ${hexToRgb(siteInfo.secondary_color)} !important;` : ''}
          }
        `}
        </style>
      </Head>
    </>
  );
};

export default StylesContainer;
