import Link from 'next/link';
import { Navbar } from 'react-bootstrap';
import Image from 'next/image';
import logo from '@/images/kglogo.svg';
import { checkImgUrl } from '@/datalayer/config';

import { useSiteInfo } from '@/context/SiteInfoContext';

export default function Logo() {
  const { siteInfo } = useSiteInfo();

  if (!siteInfo) return null;

  const logo = checkImgUrl(siteInfo.site_logo.id) + '.png';

  return (
    <Link href="/" passHref>
      <Navbar.Brand>
        <div className="header-logo">
          {/* <Image alt="logo" src={logo}  /> */}
          <Image
            alt="logo"
            src={logo}
            loading="eager"
            layout="fill"
            className="header-logo-image"
          />
        </div>
      </Navbar.Brand>
    </Link>
  );
}
