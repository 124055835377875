import { useAuth } from 'context/AuthContext';

function LoginButtons({ hideMobileMenu, status }) {
  const { openLoginModal, openRegisterModal } = useAuth();

  return (
    <>
      {status === 'unauthenticated' && (
        <>
          <div className="btn-hover-outer">
            <button
              className="button-outlined sm"
              onClick={() => {
                openLoginModal();
                hideMobileMenu();
              }}
            >
              <span>Login</span>
            </button>
          </div>
          <div className="btn-hover-outer">
            <button
              className="button-contained sm"
              onClick={() => {
                openRegisterModal();
                hideMobileMenu();
              }}
            >
              Sign Up
            </button>
          </div>
        </>
      )}
      {status === 'loading' && (
        <>
          <div className="btn-hover-outer">
            <button className="button-outlined sm bp5-skeleton">
              <span>Loading...</span>
            </button>
          </div>
          <div className="btn-hover-outer">
            <button className="button-contained sm bp5-skeleton">
              Loading...
            </button>
          </div>
        </>
      )}
    </>
  );
}

export default LoginButtons;
