import { Button } from 'react-bootstrap';

function Avatar({ session }) {
  return (
    <>
      <Button className="usermenu" variant="primary">
        {session.user.first_name != null
          ? session.user.first_name.slice(0, 1)
          : ''}
        {session.user.last_name != null
          ? session.user.last_name.slice(0, 1)
          : ''}
      </Button>
    </>
  );
}

export default Avatar;
