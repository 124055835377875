/**
 * Converts a hex color string to an RGB string in CSS format.
 * @param {string} hex - The hex color string (e.g., '#3498db' or '3498db').
 * @returns {string} The RGB string in the format 'rgb(r, g, b)'.
 * @throws Will throw an error if the hex format is invalid.
 */
export const hexToRgb = (hex) => {
  // Remove the '#' symbol if present

  if (!hex || hex == '') return '';

  hex = hex.replace(/^#/, '');

  // Handle shorthand hex notation (e.g., '03F' -> '0033FF')
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((char) => char + char)
      .join('');
  }

  // Validate that the hex string is now 6 characters long
  if (hex.length !== 6) {
    throw new Error(
      'Invalid HEX color format. It should be 3 or 6 characters long.',
    );
  }

  // Parse the red, green, and blue components
  const r = parseInt(hex.slice(0, 2), 16); // Red component
  const g = parseInt(hex.slice(2, 4), 16); // Green component
  const b = parseInt(hex.slice(4, 6), 16); // Blue component

  return `${r}, ${g}, ${b}`;
};
