import ApiService from './ApiService';

class Products {
  static createNewProduct = async (id, site_id) => {
    try {
      const { data: savedID } = await ApiService.instance.post(
        '/api/datasource/products/createproduct',
        { userid: id, site_id },
      );
      return savedID;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static getProduct = async (slug) => {
    try {
      const { data: products } = await ApiService.instance.post(
        '/api/datasource/products/getproduct',
        { slug },
      );
      return Promise.resolve(products);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static getRelatedProducts = async (data) => {
    const products = await ApiService.instance.post(
      '/api/datasource/products/get_related_products',
      data,
    );

    return products.data.products;
  };

  static getPaginatedProducts = async (data) => {
    const { data: products } = await ApiService.instance.post(
      '/api/datasource/products/get_paginated_products',
      data,
    );

    return products;
  };
}

export default Products;
